import { db } from "../firebase"; // Import the Firebase db instance

const sponsorService = {
    async getSponsors() {
        try {
            const snapshot = await db.collection("sponsors").get(); // Adjust the collection name as needed
            const sponsors = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            return sponsors;
        } catch (error) {
            console.error("Error fetching sponsors:", error);
            throw error; // Re-throw error for further handling if needed
        }
    },
};

export default sponsorService;
