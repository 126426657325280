<template>
    <div class="sponsors">
      <!-- Hero Section -->
      <section class="hero is-primary is-bold">
        <div class="hero-body">
          <div class="container has-text-centered">
            <h1 class="title is-size-1-touch">Support the PFDAA</h1>
          </div>
        </div>
      </section>
  
      <!-- Content Section -->
      <div class="container">
        <p>
          The Polish Folk Dance Association of the Americas is a nonprofit 501(c)(3). Your contributions directly support our mission to preserve and promote Polish folk culture through dance and performance.
        </p>
    
        <div class="sponsor-guidelines">
          <h3 class="subtitle is-4">Sponsorship Levels</h3>
          <ul>
            <li>$100 - Friend of Polish Folk Dance</li>
            <li>$250 - Bronze Sponsor</li>
            <li>$500 - Silver Sponsor</li>
            <li>$750 - Gold Sponsor</li>
            <li>$1000 - Platinum Sponsor</li>
          </ul>
        </div>
    
        <div class="sponsor-logos" v-if="sponsors.length > 0">
          <h3 class="subtitle is-4">Our Sponsors</h3>
          <div class="columns is-multiline">
            <div v-for="sponsor in sponsors" :key="sponsor.id" class="column is-one-quarter">
              <a :href="sponsor.link" target="_blank">
                <img :src="sponsor.logo" :alt="sponsor.name" class="sponsor-logo"/>
              </a>
            </div>
          </div>
        </div>
    
        <div class="givebutter-widget" style="text-align: center;">
          <givebutter-widget id="jDG64p"></givebutter-widget>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import sponsorService from "@/services/sponsorService";
  
  export default {
    data() {
      return {
        sponsors: [],
      };
    },
    async mounted() {
      try {
        this.sponsors = await sponsorService.getAll();
      } catch (e) {
        console.error("Error fetching sponsors:", e);
        this.sponsors = [];
      }
    }
  }
  </script>
  
  <style scoped>
  .sponsor-guidelines ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sponsor-guidelines li {
    margin: 10px 0;
  }
  
  .sponsor-logos {
    margin-top: 30px;
  }
  
  .sponsor-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  
  .givebutter-widget {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    min-width: 500px;
  }
  
  .subtitle {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .container {
    margin-top: 10px;
  }
  </style>
  